import { createBrowserRouter } from "react-router-dom";
import StartPage from "./startpage";
const router = createBrowserRouter([
{
path:"/",
element: <StartPage/>,
children: [
    {
    path: "confirmed",
    element: <div>hej</div>,
    index: true,
    }]
},
]);
export default router;