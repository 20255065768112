import './start.css';
import 'bootstrap/dist/css/bootstrap.css';
import construction from './images/construction.png';
import { useState } from 'react';
import axios from 'axios';
import { useMediaQuery } from 'react-responsive'

function StartPage() {
  const [isSubmitted, setIsSubmitted] = useState(0);
 

  function ContactForm(){
    const [contactData, setContactData] = useState({});
  
  const updateData = e => {
    setContactData({
        ...contactData,
        [e.target.name]: e.target.value
    });
  };

    function handleSubmit(event){
      event.preventDefault();
      //console.log(contactData);
      axios.post('/mail-customer', contactData)
      .then(response => {
      //console.log('Email sent successfully:', response.data);
      setIsSubmitted(1);
      })
      .catch(error => {
      //console.error('Error sending email:', error.response.data);
      setIsSubmitted(2);
    });
    }
    function useNumberLabel(){
      const isTabletOrBigMobile = useMediaQuery({ query: '(min-width: 770px)' })
      var numberLabelText = "Telefonnummer:"
      if(!(isTabletOrBigMobile)){
          numberLabelText = "Mobil:"
      }
      return(numberLabelText)
  }
    return(
      <>
        <h5 className="card-title" style={{opacity:1}}>Intresseanmälan</h5>
              <p className="card-text">Knappa in dina uppgifter så hör jag av mig!</p>
                <form onSubmit={handleSubmit} noValidate>
                 
                  <div className='row py-2'>
                    <div className='col-2'></div>
                    <div className="col-3">   
                      <label className="form-label pt-1" required>Förnamn: </label>
                    </div>
                    <div className="col-5">
                      <input name="firstName" className=" form-control" onChange={updateData}/>
                    </div>
                    <div className='col-2'></div>
                  </div>
  
                  <div className='row  py-2'>
                    <div className='col-2'></div>
                    <div className="col-3">   
                      <label className="form-label pt-1">Efternamn: </label>
                    </div>
                    <div className="col-5">
                      <input name="lastName" className=" form-control"  onChange={updateData}/>
                    </div>
                    <div className='col-2'></div>
                  </div>
  
                  <div className='row py-2'>  
                    <div className='col-2'></div>
                    <div className="col-3">   
                      <label className="form-label pt-1">E-post: </label>
                    </div>
                    <div className="col-5">
                      <input type="email" name="emailAddress"className=" form-control" onChange={updateData}/>
                    </div>
                    <div className='col-2'></div>
                  </div>
  
                  <div className='row py-2'>
                    <div className='col-2'></div>
                    <div className="col-3">   
                      <label className="form-label pt-1" >{useNumberLabel()} </label>
                    </div>
                    <div className="col-5">
                      <input type="tel" name="phoneNumber"className=" form-control" onChange={updateData}/>
                    </div>
                    <div className='col-2'></div>
                  </div>
  
                <br/>
  
                <input type="submit" value="Skicka" className="btn btn-primary m-2" ></input>
                </form>
      </>
    );
  }
function Submitted(){
  return(
    <div>
    <h2 style={{color:"#FFC107"}}>Tack för din intresseanmälan!</h2><br></br>
    <h5>Jag hör av mig inom kort.</h5>
    </div>
  );
}
function NotSubmitted(){
  return(
    <div>
    <h2 style={{color:"red"}}>Något gick fel!</h2><br></br>
    <h5>Maila mig på<br/><a href = "mailto: joakim.gernersson@jgcoaching.se" style={{color:"#FFC107"}}>joakim.gernersson@jgcoaching.se</a><br/>istället!</h5>
    </div>
  );
}

  
  return (
    <div>
      <div className="start" style={{fontFamily: "Outfit, sans-serif", fontWeight: 300}}>
        <div className='container' >
        <div className='row align-items-center'>  
          <div className='col'></div>
          <div className={useDeviceWidth()}>
            <div className='text-center'>
            <img width="70%" alt="Bild på en byggskylt." src={construction}></img>
            </div>
          <div className="card text-white bg-dark mt-1" style={{"--bs-bg-opacity": .9}}>
            <div className="card-body">
              <h2 className='text-center' >JG Coaching</h2>
        
              <div className='row'>
                <div className='col-12 text-center'>Sätt igång din resa mot en starkare och friskare version av dig själv med JG Coaching. Välkommen till din väg mot en hälsosam livsstil.</div>
                <div className='col'> </div>
              </div>
            </div>
          </div>
          <div className="card text-center text-white bg-dark mt-4" style={{"--bs-bg-opacity": .9, marginBottom:"10%"}}>
            <div className="card-header">
              Kontakta mig
            </div>
            <div className="card-body">
              
              {(() => {
                  if (isSubmitted===0) {
                    return (
                      <ContactForm/>
                    )
                  } else if (isSubmitted===1) {
                    return (
                      <Submitted/>
                    )
                  } else {
                    return (
                      <NotSubmitted/>
                    )
                  }
                })()}
          </div>
          </div>
          </div>
          <div className='col'></div>
          </div>
        </div>
      </div>
    </div>
  );
}
function useDeviceWidth() {
  const isBigScreen = useMediaQuery({query: '(min-width: 1400px)'})
  const isLaptop = useMediaQuery({ query: '(min-width: 992px)' })
  const isTabletOrBigMobile = useMediaQuery({ query: '(min-width: 770px)' })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  var colWidth = "";
  if(isBigScreen){
      colWidth = "col-5"
  } else if (isLaptop){
      colWidth = "col-7"
  } else if (isTabletOrBigMobile){
      colWidth = "col-10"
  } else {
      colWidth = "col-12"
  
  }
   return(colWidth);
  }
  
  



export default StartPage;
